<template>
  <b-container fluid id="app" style="height: 100%;">
    <b-row fluid>
        <b-col v-if="orders.length == 0 && baskets.length == 0">
            <h1>No orders, and no baskets!</h1>
            <h2>Go get some sales!</h2>
        </b-col>
        <b-col :cols="3" v-for="order in orders" v-bind:key="order.id">
            <b-card :title="'Order ' + order.id" @click="selectOrder(order)" :bg-variant="backgroundVariant(order)" :border-variant="borderVariant(order)" class="my-2">
                <b-row class="py-1">
                    <b-col v-if="order.first_name">{{order.first_name}} {{order.last_name}}</b-col>
                    <b-col v-if="!order.first_name">{{order.user.first_name}} {{order.user.last_name}}</b-col>
                </b-row>
                <div v-if="!selectedOrder || (selectedOrder && selectedOrder.id != order.id)">
                    <b-row class="py-1" v-for="line in order.lines" v-bind:key="line.id">
                        <b-col class="text-left">
                            <b-badge variant="dark" class="mr-2">{{line.quantity}}</b-badge> {{line.item.name}}
                        </b-col>
                    </b-row>
                </div>
                <div v-if="selectedOrder && selectedOrder.id == order.id">
                    <b-button v-if="!selectedOrder.additional_info.super5ives_picking" @click.prevent="setPickingFlag(selectedOrder)" variant="success" size="lg" class="w-100">Select for picking</b-button>
                    <b-button v-if="selectedOrder.additional_info.super5ives_picking && selectedOrder.state != 'rtd'" @click.prevent="setRTD(selectedOrder)" variant="success" size="lg" class="w-100">Mark as ready</b-button>
                    <b-button v-if="selectedOrder.state == 'rtd'" @click.prevent="completeOrder(selectedOrder)" variant="success" size="lg" class="w-100">Complete order</b-button>
                </div>
            </b-card>
        </b-col>
        <b-col :cols="3" v-for="basket in baskets" v-bind:key="basket.id" class="my-2">
            <b-card :title="'Basket ' + basket.id">
                <b-row class="py-1">
                    <b-col v-if="basket.first_name || basket.last_name">{{basket.first_name}} {{basket.last_name}}</b-col>
                    <b-col v-if="!basket.first_name && !basket.last_name && basket.user">{{basket.user.first_name}} {{basket.user.last_name}}</b-col>
                    <b-col v-if="!basket.first_name && !basket.last_name && !basket.user"><i>Anonymous</i></b-col>
                </b-row>
                <b-row class="py-1" v-for="line in basket.lines" v-bind:key="line.id">
                    <b-col class="text-left">
                        <b-badge class="mr-2">{{line.quantity}}</b-badge> {{line.item.name}}
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
    <b-modal id="login" title="Log in" ok-title="Log in" no-close-on-esc no-close-on-backdrop ok-only @ok="doLogin">
        <b-form-input placeholder="Username" v-model="loginUser" />
        <b-form-input type="password" placeholder="Password" v-model="loginPass" />
    </b-modal>
    <div class="statusbox">
        <span v-if="realtimeSocket && realtimeSocket.readyState == 0">Connecting...</span>
        <span v-if="realtimeSocket && realtimeSocket.readyState == 1">Connected</span>
        <span v-if="realtimeSocket && realtimeSocket.readyState == 2">Disconnecting...</span>
        <span v-if="realtimeSocket && realtimeSocket.readyState == 3">Disconnected</span>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'App',
  data: function(){
    return {
      baseUrl: this.$store.state.base_url,
      loginUser: null,
      loginPass: null,
      loginError: null,
      deselectTimeout: null,
      selectedOrder: null,
      realtimeSocket: null,
      orders: [],
      baskets: [],
    }
  },
  mounted(){
    this.refreshAll();
    this.setupRealtime();
    window.setInterval(this.refreshAll, 60000);
  },
  methods: {
    refreshAll(){
        if(!this.$store.state.token){
            this.$bvModal.show("login");
        }else{
            this.refreshOrders();
            this.refreshBaskets();
        }
    },
    setupRealtime(){
        if(!this.$store.state.token){
            this.$bvModal.show("login");
        }else{
            if(this.realtimeSocket && this.realtimeSocket.readyState == 1) return;

            this.realtimeSocket = new WebSocket(
                this.baseUrl.replace("https://", "wss://").replace("http://", "ws://") + "/ws/super5ives/dashboard?token=token:" + this.$store.state.token + ":" + this.$store.state.secret);
            this.realtimeSocket.onerror = () => {
                window.setTimeout(this.setupRealtime, 10000)
            }
            this.realtimeSocket.onclose = () => {
                window.setTimeout(this.setupRealtime, 5300)
            }
            this.realtimeSocket.onmessage = this.realtimeMessage;
        }
    },
    borderVariant(order){
        if(order.state == "hold") return "warning";
        if(order.state == "rtd") return "success";
        if(order.state == "process") return "info";
    },
    backgroundVariant(order){
      if(this.selectedOrder && (order.id == this.selectedOrder.id)) return 'primary';
      if(order.additional_info.super5ives_picking && order.state != "rtd") return 'danger';
      if(order.state == "rtd") return 'success';
      return '';
    },
    selectOrder(order){
      this.selectedOrder = order;
      if(this.deselectTimeout) window.clearTimeout(this.deselectTimeout);
      this.deselectTimeout = window.setTimeout(() => { this.selectOrder(null); }, 10000);
    },
    order_on_site(order){
        return parseInt(order.additional_info.super5ives_on_site) || order.shipping_code == "OnSiteDelivery@CNC-ONSITE";
    },
    refreshOrders(){
        this.$http.get(this.baseUrl + "/api/v2/shop/orders", {
            params: {
                state: "process,rtd,hold",
            }
        }).then((response) => {
            this.orders = response.data._objects.filter(this.order_on_site).sort((x,y) => {
                if(x.created_at > y.created_at) return 1;
                if(x.created_at < y.created_at) return -1;
                return 0;
            });
        })
    },
    refreshBaskets(){
        var minsAgo15 = new Date((new Date()).getTime() - 60*15*1000);
        this.$http.get(this.baseUrl + "/api/v2/shop/baskets", {
            params: {
                state: "process,rtd,hold",
                filters: JSON.stringify({
                    last_modified__gt: minsAgo15.toISOString(),
                }),
                count: 99999,
            }
        }).then((response) => {
            this.baskets = response.data._objects.sort((x,y) => {
                if(x.created_at > y.created_at) return 1;
                if(x.created_at < y.created_at) return -1;
                return 0;
            });
        })
    },
    realtimeMessage(event){
        var evdata = JSON.parse(event.data);
        if(evdata.event == "basket.update"){
            var basket = this.baskets.filter((basket) => { return(basket.id == evdata.basket.id); });
            if(basket.length){
                for(let [key, value] of Object.entries(evdata.basket)){
                    this.$set(basket[0], key, value);
                }
            }else{
                this.baskets.push(evdata.basket);
            }
        }
        if(evdata.event == "order.update"){
            if(evdata.order.state == "complete") return;
            var order = this.orders.filter((order) => { return(order.id == evdata.order.id); });
            if(order.length){
                for(let [key, value] of Object.entries(evdata.order)){
                    this.$set(order[0], key, value);
                }
            }else{
                if(parseInt(evdata.order.additional_info.super5ives_on_site))
                    this.orders.push(evdata.order);
            }
        }
    },
    setPickingFlag(order){
        this.$http.post(this.baseUrl + "/api/v2/shop/orders", {
            _id: order.id,
            id: order.id,
            additional_info: {
                super5ives_picking: 1
            }
        })
        this.selectedOrder = null;
    },
    setRTD(order){
        this.$http.post(this.baseUrl + "/api/v2/shop/orders", {
            _id: order.id,
            id: order.id,
            state: "rtd",
        })
        this.selectedOrder = null;
    },
    completeOrder(order){
        this.$http.post(this.baseUrl + "/api/v2/shop/orders", {
            _id: order.id,
            id: order.id,
            state: "complete",
        })
        this.orders = this.orders.filter((x) => { return x.id != order.id; });
        this.selectedOrder = null;
    },
    doLogin(){
        if(this.loginUser != "" && this.loginPass != "") {
            const fd = new FormData();
            fd.append("username", this.loginUser);
            fd.append("password", this.loginPass);
            this.$http.post(this.baseUrl + "/api/tokens", fd).then((data) => {
                this.$store.commit('setAuth', {"token": data.data.token, "secret": data.data.secret});
                this.refreshOrders();
                this.refreshBaskets();
                this.setupRealtime();
            }).catch((error) => {
                if(error.response){
                    this.loginError = error.response.data.error;
                    this.$bvModal.show("login");
                }else{
                    this.loginError = error.message;
                    this.$bvModal.show("login");
                }
            })
        } else {
            console.log("A username and password must be present");
            this.loginError = "You must supply a username and password";
        }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 1em;
}

.card {
    height: 400px;
    max-height: 400px;
    overflow-y: scroll;
}
.statusbox {
    position: fixed;
    bottom: 0px;
    right: 0px;
    color: white;
}
</style>
